import React from 'react'

const Loading = () => {
  return (
    <main>
      <div className='loading'></div>
    </main>
  )
}

export default Loading
